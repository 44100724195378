<template>
  <div class="full-page checkout-container">
    <div class="section-summary-sticky">
      <CheckoutSummaryStickyAccordion
        isShowLabelExpand
        class="dp-block dp-lg-none"
      >
        <template v-slot:title>
          <div class="title-semibold">
            {{ finalPrice | currency2 }}
          </div>
        </template>
        <template v-slot:content>
          <div class="section-summary-sticky-accordion">
            <CheckoutProductItems
              :data="productCartItems"
              @remove="onRemoveBundleAndAdditional"
            />
            <hr class="divider-gray-83" />

            <!-- Coupon -->
            <CheckoutApplyCoupon key="summary-sticky" />

            <hr class="divider-gray-83" />

            <CheckoutSummary
              :cartSummaryData="cartSummaryData"
              :isDeposit="isDeposit"
              :isPickupAtStore="!isDelivery"
              :shipDate="shipDate"
              :isShipDateHighlight="isShipDateHighlight"
            />
          </div>
        </template>
      </CheckoutSummaryStickyAccordion>
    </div>
    <div class="left-container">
      <div class="section-information">
        <CheckoutBreadcrumb />

        <CheckoutCustomerForm
          :shipDate="shipDate"
          :isShipDateHighlight="isShipDateHighlight"
        >
          <template v-slot>
            <div class="section-summary">
              <div v-if="productCartItems.length == 1">
                <div class="headline-m">
                  รายการสั่งซื้อ
                </div>

                <CheckoutProductItems
                  :data="productCartItems"
                  @remove="onRemoveBundleAndAdditional"
                  class="mt-4"
                />
              </div>

              <PDPAccordionItem
                v-if="productCartItems.length > 1"
                isShowLabelExpand
              >
                <template v-slot:title>
                  <div class="headline-m">
                    รายการสั่งซื้อ ({{ productCartItems.length }})
                  </div>
                </template>
                <template v-slot:content>
                  <CheckoutProductItems
                    :data="productCartItems"
                    @remove="onRemoveBundleAndAdditional"
                    class="mt-4"
                  />
                </template>
              </PDPAccordionItem>

              <!-- Coupon -->
              <CheckoutApplyCoupon key="summary-main" />

              <CheckoutSummary
                :cartSummaryData="cartSummaryData"
                :isDeposit="isDeposit"
                :isPickupAtStore="!isDelivery"
                :shipDate="shipDate"
                :isShipDateHighlight="isShipDateHighlight"
              />
            </div>
          </template>
        </CheckoutCustomerForm>

        <Footer />
      </div>
    </div>
    <div class="right-container">
      <div class="section-summary dp-none dp-lg-flex">
        <CheckoutProductItems
          :data="productCartItems"
          @remove="onRemoveBundleAndAdditional"
        />
        <hr class="divider-gray-83" />

        <!-- Coupon -->
        <CheckoutApplyCoupon key="summary-main" />

        <hr class="divider-gray-83" />

        <CheckoutSummary
          :cartSummaryData="cartSummaryData"
          :isDeposit="isDeposit"
          :isPickupAtStore="!isDelivery"
          :shipDate="shipDate"
          :isShipDateHighlight="isShipDateHighlight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import config from '@/config/index'
const { showFreeBie } = config
import isBnn from '@/config/isBnn'
import colorBnn from '@/config/color'
import cartSummary from '@/mixins/cartSummary'
import branchTypes from '@/config/branchTypes'
import { SHIPPING_DATE } from '@/config/index'
import moment from 'moment'

export default {
  name: 'Checkout',

  mixins: [cartSummary],

  components: {
    CheckoutBreadcrumb: () =>
      import('@/components/studio7/CheckoutBreadcrumb'),
    CheckoutCustomerForm: () =>
      import('@/components/studio7/CheckoutCustomerForm'),
    // CheckoutDeliveryMethod: () =>
    //   import('@/components/studio7/CheckoutDeliveryMethod'),
    CheckoutProductItems: () =>
      import('@/components/studio7/CheckoutProductItems'),
    CheckoutApplyCoupon: () =>
      import('@/components/studio7/CheckoutApplyCoupon'),
    CheckoutSummary: () =>
      import('@/components/studio7/CheckoutSummary'),
    PDPAccordionItem: () =>
      import('@/components/studio7/PDPAccordionItem'),

    CheckoutSummaryStickyAccordion: () =>
      import('@/components/studio7/CheckoutSummaryStickyAccordion'),

    Footer: () => import('@/components/bnn/Footer'),
  },

  data() {
    return {
      // Import
      colorBnn,
      isBnn,

      // Data
      form: {
        couponCode: '',
      },
      formCheckSummary: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        address: null,
        sub_district: null,
        district: null,
        province: null,
        postcode: null,
        // Notes: Hide default value from local storage
        // first_name: localStorage.getItem('firstName') || null,
        // last_name: localStorage.getItem('lastName') || null,
        // phone: localStorage.getItem('phoneNumber') || null,
        // email: localStorage.getItem('email') || null,
        // address: localStorage.getItem('address') || null,
        // sub_district: localStorage.getItem('sub_district') || null,
        // district: localStorage.getItem('district') || null,
        // province: localStorage.getItem('province') || null,
        // postcode: localStorage.getItem('postcode') || null,
        product_id: '',
        bundle_products: [],
        delivery: false,
        is_delivery: false,
      },
      freebieListByCouponModal: false,
    }
  },

  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      campaign: state => state.campaign.current,
      cartSummaryData: state => state.cart.cartSummaryData,
      branchType: state => state.branchStore.branchType,
      cartDelivery: state => state.cart.delivery,
    }),

    ...mapGetters({
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      cartItems: 'cart/cartItems',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      bundleItems: 'cart/bundleItems',
      discountOnTop: 'cart/discountOnTop',
      deliveryItems: 'cart/deliveryItems',
      getBundleDelivery: 'model/getBundleDelivery',
      accessoryBundles: 'model/getAccessoryBundles',
      // getFreebieHandraiser
      handraiserFreebies: 'model/getHandraiserFreebies' ?? [],
    }),

    productCartItems() {
      const cartItems =
        this.cartItems.filter(item => item.type !== 'delivery') || []

      if (!showFreeBie) {
        return cartItems?.filter(item => !item?.isFreeBie)
      }
      return cartItems
    },

    isShipDateHighlight() {
      let highlight =
        this.selectSKU?.actual_date &&
        moment(this.selectSKU?.actual_date).isAfter(
          moment(SHIPPING_DATE.FIRST_LOT),
        )

      let className =
        this.selectSKU?.actual_date === SHIPPING_DATE.SECOND_LOT
          ? 'ship-date-highlight lot-2'
          : this.selectSKU?.actual_date === SHIPPING_DATE.THIRD_LOT
          ? 'ship-date-highlight lot-3'
          : ''

      return highlight ? className : ''
    },

    shipDate() {
      return this.selectSKU?.active
        ? this.selectSKU.shipping_date ||
            this.campaign?.data?.shipdate ||
            '-'
        : 'สินค้าไม่พร้อมบริการ'
    },

    branchNameSelected() {
      return this.formCheckSummary.branch_name
    },

    isDeposit() {
      return this.branchType === branchTypes.BRANCH_TYPE_DEPOSIT
    },

    isDelivery() {
      return (
        this.cartDelivery === 'delivery' || this.cartDelivery === true
      )
    },

    finalPrice() {
      return this.cartSummaryData?.final_price ?? 0
    },
  },

  methods: {
    onRemoveBundleAndAdditional(item) {
      this.$store.dispatch('bundle/toggle', item)
      this.$forceUpdate()
      this.checkValidateCoupon()
      this.checkSummary()
    },

    async checkValidateCoupon() {
      if (this.form.couponCode.trim()) {
        const payload = {
          productId: this.selectSKU.id,
          couponCode: this.form.couponCode,
          bundleItems: this.bundleItems,
          paymentTypeId: this.formCheckSummary.payment_type_id,
        }

        await this.$store.dispatch('cart/checkCouponCode', payload)

        if (this.coupon.autoAddToCart) {
          this.HandleFreebieWithCoupon('add')
        }

        this.checkSummary()
      }
    },

    HandleFreebieWithCoupon(key) {
      if (this.coupon.autoAddToCart) {
        switch (key) {
          case 'remove':
            this.bundleItems.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/remove', item)
              }
            })
            break

          default:
            this.handraiserFreebies?.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/add', item)
              }
            })
            break
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.checkout-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: minmax(min-content, calc(50% + 72px)) 1fr;

        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    > .left-container {
        width 100%
        max-width: 570px;
        background-color: #fff
        border-right: none;
        margin: 0 auto;
        min-height: 100dvh;

        @media screen and (min-width: 1024px) {
            max-width: 100%;
            border-right: 1px solid #dedede;
            margin: 0;
        }

        > .section-information {
            width 100%
            max-width: 100dvw
            padding: 0 21px 0;
            display: flex;
            flex-direction: column;
            gap: 26px;

            @media screen and (min-width: 1024px)  {
                padding: 0 22px 0;
                max-width: 660px;
                margin-left: auto;
            }
        }
    }

    >.right-container {
        width 100%
        background-color: #fff
        position: relative;


        @media screen and (min-width: 1024px) {
            width 100%
            background-color: $color-grey-light-80;
            min-height: 100dvh;
        }

        >.section-summary {
            width 100%
            max-width: 100dvw
            padding: 21px;

            display flex;
            flex-direction: column;
            gap: 32px;

            @media screen and (min-width: 1024px) {
                position: sticky;
                top: 72px;

                padding: 38px;
                width 100%
                max-width: 520px;
                margin-right: auto;

                display flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}

.section-summary-sticky {
    background-color: $color-grey-light-80;

    grid-column: span 2;
}

.section-summary-sticky-accordion {
    width 100%
    max-width: 100dvw
    padding: 21px 21px 0;

    display flex;
    flex-direction: column;
    gap: 21px;
}

.full-page
    overflow: visible !important
</style>
